<template>
  <div>
    <b-card no-body bg-variant="dark">
      <b-row style="padding-bottom: 20px;">
        <b-col cols="12">
          <b-button variant="secondary" size="md" class="pull-right" @click="add_new_wisdom_data('wdm14')"><i class="fa fa-plus"></i> Yeni teklif al </b-button>
        </b-col>
      </b-row>
      <b-row style="margin: 0px;">
        <b-col cols="4">
          <template v-for="(x, x_ind) in d_userWdmrList">
            <b-card style="margin-bottom: 5px;">
              <b-col cols="12">
                {{ x.name.label }}
              </b-col>
            </b-card>
            <template v-for="(r, r_ind) in x.list">
              <b-card :style="d_selectedWdmType === x_ind && d_selectedWdmrIndex === r_ind ? 'margin-left: 20px; margin-bottom: 5px; cursor: pointer; background: #9adcd3;' : 'margin-left: 20px; margin-bottom: 5px; cursor: pointer;'" no-body @click="f_selectWdmr(x_ind, r_ind)">
                <b-col cols="12">
                  {{ r_ind + 1 }} - {{ r.label }}
                </b-col>
                <b-card-footer footer-bg-variant="primary" header-text-variant="dark" class="p-1" style="background-color: #5db5bd!important;"></b-card-footer>
              </b-card>
            </template>
          </template>
        </b-col>
        <b-col cols="8">
          <template v-if="Object.keys(d_selectedWdmrData).length > 0">
            <b-card no-body>
              <b-card-header header-bg-variant="sedondary" header-text-variant="dark" class="p-1">
                {{ d_selectedWdmrData.label }}
                <template v-if="d_selectedWdmrData && d_selectedWdmrData.data && d_selectedWdmrData.data.general && d_selectedWdmrData.data.general.form_status && d_selectedWdmrData.data.general.form_status.val && d_selectedWdmrData.data.general.form_status.val.value">
                  <b-button v-if="d_selectedWdmrData.data.general.form_status.val.value === 'preparing'" variant="secondary" style="margin-right: 5px;" size="sm" class="pull-right" @click="f_editWdmr()"><i class="fa fa-edit"></i> düzenle </b-button>
                  <b-button v-if="d_selectedWdmrData.data.general.form_status.val.value === 'preparing'" variant="secondary" style="margin-right: 5px;" size="sm" class="pull-right" @click="f_sendToAuthority()"><i class="fa fa-send"></i> yetkiliye gönder </b-button>
                </template>
              </b-card-header>
              <!-- <wisdom-data-show :change="change" :view_mode="'table'" :data="d_selectedWdmrData" :data_type="'wdm14'" :option_data="option_data['wdm14']"></wisdom-data-show> -->
            </b-card>
          </template>
        </b-col>
      </b-row>
    </b-card>
    <modal v-if="show_wisdom_data_modal" @close="show_wisdom_data_modal = false" :width="'750'">
      <h3 slot="header"> Yeni Teklif Alma Formu </h3>
      <div slot="body">
        <!-- <wisdom-data-modal v-if="dShowTrueFalse" ref="wisdom_data_modal" :data="wisdom_data" :data_type="data_type" :option_data="option_data" :rule_engine="false" :query_list="[]"></wisdom-data-modal> -->
        <b-card>
          <b-row>
            <b-col sm="12" md="4">
              <b-button variant="success" @click="f_calculatePrice()">Fiyat Hesapla</b-button>
            </b-col>
            <b-col sm="12" md="8">
              <strong style="color: red;">
                Hesaplanan ücretler sadece yazılım, bakım, güncelleme içindir. Eğitim ve destek ücretleri görüşme esnasında değerlendirilecektir.
              </strong>
            </b-col>
          </b-row>
        </b-card>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="wisdomDataSave()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="show_wisdom_data_modal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  default as Modal
} from "@/components/widgets/Modal";
/*import {
  default as WisdomDataShow
} from '@/components/widgets/WisdomDataShow';
*/
/*import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
*/import { mapGetters } from 'vuex';
import vSelect from 'vue-select';
import { DateFunctions } from '@/services/public/date_functions';
import OptionService from '@/services/option';
import FileTransferService from '@/services/file_transfer';
import WisdomDataService from '@/services/wisdom_data';
import { ClsWdmrForever, ClsWdmrSave } from "@/services/public/wdm";
import GlobalService from "@/services/global";
import CustomerService from "@/services/customer";
import moment from 'moment';
export default {
  name: 'Offer',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {
    vSelect,
    // WisdomDataShow,
    // WisdomDataModal,
    Modal
  },
  props: {},
  data () {
    return {
      dShowTrueFalse: true,
      titck_list: [],
      d_showTitckModal: false,
      dTitckData: { 'file': '', 'date': '' },
      d_titckRows: [],
      d_titckColumns: [{
        key: "actions",
        label: ""
      }, {
        key: "rowNumber",
        label: " "
      }, {
        key: "barcode_no",
        label: "Barkod No",
        sortable: true
      }, {
        key: "ingredient_name",
        label: "İçerik",
        sortable: true
      }, {
        key: "brand_name",
        label: "Ürün Adı",
        sortable: true
      }, {
        key: "wisdom_status",
        label: "WisdomEra",
        sortable: true
      }],
      d_selectedBrandData: '',
      wisdom_data_model_3_data: {},
      brand_list: [],
      selected_general_tab: 'ingredients',
      change: "0",
      d_selectedIngredientData: '',
      d_searchText: '',
      data_type: '',
      option_data: {},
      wisdom_data: {},
      show_wisdom_data_modal: false,
      d_searchMaterialList: [],
      d_searchMaterialColumns: [{
        key: "label",
        label: "İçerik Adı"
      }],
      wisdom_op: 'new',
      wdm_key: '',
      d_selectedBrand: '',
      d_selectedBrandKey: '',
      d_selectedBrandIndex: '',
      wisdom_data_model_3_data_key: '',
      d_showBrandData: true,
      titck_excel_doc: '',
      d_showTitckTable: true,
      d_selectedTitckInd: '',
      d_titckSearchData: { 'search': '' },
      d_userWdmrList: {},
      d_selectedWdmType: '',
      d_selectedWdmrIndex: '',
      d_selectedWdmrData: {},
      change: "0"
    };
  },
  created: function () {
    let query = 'data_type=wdm14&location=wisdom';
    OptionService.get_last_wdm_of_this_type(query)
      .then(resp => {
        if (resp.data.status === 'success') {
          this.option_data['wdm14'] = resp.data.result;
        } else {
          console.log('error ', resp.data.message);
        }
      });
    this.f_getUserWdmrList();
  },
  mounted: function () {},
  methods: {
    f_showTrueFalse: function () {
      this.dShowTrueFalse = false;
      setTimeout(function () {
        this.dShowTrueFalse = true;
      }.bind(this), 500)
    },
    f_calculatePrice: function () {
      if (this.wisdom_data && this.wisdom_data.data && this.wisdom_data.data.general && this.wisdom_data.data.general.added_modules && Object.keys(this.wisdom_data.data.general.added_modules).length > 0) {
        for (let i in this.wisdom_data.data.general.added_modules) {
          if (!this.wisdom_data.data.general.added_modules[i].detail.user_count || (this.wisdom_data.data.general.added_modules[i].detail.user_count && !this.wisdom_data.data.general.added_modules[i].detail.user_count.val)) {
            let modal_data = { 'type': 'alert', 'text': 'Lütfen ilgili modüllere ait kullanıcı sayısınız belirtiniz', 'centered': true, 'title': 'Fiyat Hesaplama İşlemi Hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
            return;
          }
        }
      } else {
        let modal_data = { 'type': 'alert', 'text': 'Lütfen modül seçimi yapınız', 'centered': true, 'title': 'Fiyat Hesaplama İşlemi Hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      let data = { 'wisdom_data': this.wisdom_data };
      CustomerService.calculate_product_price(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === "success") {
            // console.log(resp.data.result);
            this.wisdom_data = resp.data.result;
            this.$forceUpdate();
          } else {
            msg = 'error: ' + resp.data.message;
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': this.$t('wdm16.13274') };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
        })
    },
    f_sendToAuthority: function () {
      this.wisdom_op = 'edit';
      this.data_type = 'wdm14';
      this.d_selectedWdmrData.data.general.form_status.val = { 'label': 'Yetkiliye Gönderildi', 'value': 'sended' };
      let data = { 'data': this.d_selectedWdmrData };
      CustomerService.save_offer(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === "success") {
            this.d_userWdmrList[this.d_selectedWdmType]['list'][this.d_selectedWdmrIndex] = resp.data.result.wdmr_key_data;
            this.d_selectedWdmrData = resp.data.result.wisdom_data;
            this.wisdom_show_update();
            msg = this.$t('wdm16.13275');
          } else {
            msg = 'error: ' + resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          this.$forceUpdate();
        });
    },
    f_editWdmr () {
      this.wisdom_op = "edit";
      this.data_type = 'wdm14';
      this.wisdom_data = JSON.parse(JSON.stringify(this.d_selectedWdmrData));
      this.show_wisdom_data_modal = true;
    },
    wisdom_show_update: function () {
      if (this.change === "0") {
        this.change = "1";
      } else {
        this.change = "0";
      }
      this.$forceUpdate();
    },
    f_getWdmrData: function (wdmr_key) {
      let data = { 'wdmr_key': wdmr_key };
      CustomerService.get_wdmr_data(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === "success") {
            this.d_selectedWdmrData = resp.data.result;
          } else {
            this.d_selectedWdmrData = {};
            console.log('errorr f_getUserWdmrList : ', resp.data.message);
          }
          this.wisdom_show_update();
        });
    },
    f_selectWdmr: function (x_ind, r_ind) {
      this.d_selectedWdmType = x_ind;
      this.d_selectedWdmrIndex = r_ind;
      this.f_getWdmrData(this.d_userWdmrList[x_ind]['list'][r_ind]['key']);
    },
    f_getUserWdmrList: function () {
      let data = { 'wdm_list_type': 'selected', 'wdm_list': ['wdm14'] };
      CustomerService.get_user_wdmr_list(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === "success") {
            this.d_userWdmrList = resp.data.result;
            if (this.d_userWdmrList['wdm14'] && this.d_userWdmrList['wdm14']['list'].length > 0) {
              this.d_selectedWdmrIndex = 0;
              this.d_selectedWdmType = 'wdm14';
              this.f_getWdmrData(this.d_userWdmrList[this.d_selectedWdmType]['list'][this.d_selectedWdmrIndex]['key']);
            }
          } else {
            this.d_userWdmrList = {};
            console.log('errorr f_getUserWdmrList : ', resp.data.message);
          }
        });
    },
    add_new_wisdom_data: function (data_type) {
      this.data_type = data_type;
      this.wisdom_op = "new";
      let pre_data = {
        'id': 'new',
        'data': {},
        'type': data_type,
        'reference_wdm': {},
        'parent': data_type
      };
      pre_data['reference_wdm']['version'] = this.option_data[this.data_type]['version'];
      pre_data['reference_wdm']['owner_type'] = this.option_data[this.data_type]['owner_type'];
      if (this.option_data[this.data_type]['owner_type'] === 'wisdom') {
        pre_data['reference_wdm']['key'] = 'wdm_wisdom_' + this.data_type + '_v' + this.option_data[this.data_type].version;
      }
      for (let i in this.option_data[this.data_type].param_group.param_order) {
        pre_data['data'][this.option_data[this.data_type].param_group.param_order[i]] = {};
      }
      pre_data['data']['general']['form_status'] = {
        'val': { 'label': 'Hazırlanıyor', 'value': 'preparing' }
      };
      this.wisdom_data = pre_data;
      this.show_wisdom_data_modal = true;
    },
    wisdomDataSave: function () {
      let return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data[this.data_type], ['name', 'delete']);
      let wdmr_name = return_data.name;
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      if (!only_one_param_is_filled) {
        let modal_data = { 'type': 'alert', 'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.', 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' * ' + wdmr_require_alerts[r].label;
        }
        let modal_data = { 'type': 'alert', 'text': require_txt, 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      this.$refs.wisdom_data_modal.calculate_name();
      let data = { 'data': this.wisdom_data };
      CustomerService.save_offer(data)
        .then(resp => {
          let msg = '';
          if (resp.data.status === "success") {
            this.show_wisdom_data_modal = false;
            if (!this.d_userWdmrList[this.data_type]) {
              this.d_userWdmrList[this.data_type] = { 'list': [], 'name': this.option_data[this.data_type].name };
            }
            if (this.wisdom_op === 'new') {
              this.d_userWdmrList[this.data_type]['list'].push(resp.data.result.wdmr_key_data);
              this.d_selectedWdmrData = resp.data.result.wisdom_data;
              this.d_selectedWdmrIndex = this.d_userWdmrList[this.data_type]['list'].length - 1;
              this.d_selectedWdmType = this.data_type;
            } else {
              this.d_userWdmrList[this.d_selectedWdmType]['list'][this.d_selectedWdmrIndex] = resp.data.result.wdmr_key_data;
              this.d_selectedWdmrData = resp.data.result.wisdom_data;
              this.wisdom_show_update();
            }
            msg = this.$t('wdm16.13275');
          } else {
            msg = 'error: ' + resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          this.$forceUpdate();
        });
    }
  },
  watch: {}
};

</script>

<style></style>

